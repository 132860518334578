import React  from 'react';
import HomeHero from '../img/Home Hero.jpg'
import QuotationMark from '../img/Quotation.png'
import LubrosCafe from '../img/Lubros Picture.png'


function App(props) {
  const setPage = (page) => {
    props.setPage(page)
  }

  return (
    <div className="contents-container">
        <div className="section heading">
            Come Celebrate!
        </div>
        <img className="section hero" src={HomeHero} />
        <div className="section subheading">
            Born in China. Loved worldwide. Kind of.
        </div>
        <div className="section text">
        For just over a quarter of a century, the LuBros have shared their love with people of all ages (partly) around the globe.
        Over this time, the LuBros have gained the attention of many. Was this attention good?
        Who can be sure? The only thing we are sure of is that they have made lots many enemies and accrued lots of debt.
        <br/><br/>
        They have ventured to many foreign lands in search of knowledge but alas, their pea brains were unable to hold onto such important
        information. The only things they got from their adventures were more debt and sunburns. And so, their neverending search
        continues. 
        <br/><br/>
        Wisdom is chasing, but they are faster.
        </div>
        <div className="divider"></div>
        <div className="section quote">
            <img src={QuotationMark} className="opening"/>
            <div className="subheading">What makes LuBros special?</div>
            <div className="quote-text">
                To be frank - nothing really.<br/><br/>
                
            </div>
            <img src={QuotationMark} className="closing"/>
        </div>
        <div className="divider"></div>
        <div className="section half">
            <div className="text">
                <div className="text-container">
                    <div className="subheading">LuBros Cafe is here!</div>
                    <div>
                        Over the past few months, our staff have been working tirelessly to 
                        deliver to you the long-awaited LuBros Cafe. Whether it be cleaning the venue,
                        stocking up the matcha or labelling the merchandise, our boys have worked hard
                        to ensure that your experience at the LuBros Cafe is truly exquisite.
                        <br/><br/>
                        Although we do not have a lot of staff, rest assured that your matcha will be whisked
                        with lots of love and care. There is also no guarantee that Small Bear has not nibbled on
                        the spinach and ricotta bureka, but don't worry - we're sure he washed his hands
                        beforehand.
                        <br/><br/>
                        Come hungry, come keen. This experience is only available for a limited time!
                        <br/><br/>
                    </div>
                    <div className="button" onClick={() => setPage("staff")}>View Staff</div>
                </div>
            </div>
            <img src={LubrosCafe} />
        </div>
        <div className="section grey">
            <div className="text">
                <div className="text-container">
                    <div className="subheading">LuBros Cafe is here!</div>
                    <div>
                    Over the past few months, our staff have been working tirelessly to 
                    deliver to you the long-awaited Jellycat Cafe.
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}

export default App;
