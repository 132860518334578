import React  from 'react';

function App(props) {  
  return (
    <div className="footer">
        <section>
            <div className="subheading">
                Hungry
            </div>
            <div>
                Food
            </div>
            <div>
                Drinks
            </div>
        </section>
        <div className="divider"></div>
        <section>
            <div className="subheading">
                About Us
            </div>
            <div>
                Our Story
            </div>
            <div>
                Our Staff
            </div>
            <div>
                Jelly Jobs
            </div>
            <div>
                Locations
            </div>
        </section>
    </div>
  );
}

export default App;
