import React, {useState} from 'react';
import Menu from './Menu'
import Footer from './Footer'

import Home from '../pages/Home'
import Plan from '../pages/Plan'
import Food from '../pages/Food'
import Drinks from '../pages/Drinks'
import Staff from '../pages/Staff'

function App() {
  const [page, setPage] = useState(null);
  return (
    <div className="page-container">
        <Menu setPage={setPage}/>
        {(page === null || page === "home") && <Home setPage={setPage}/>}
        {page === "plan" && <Plan />}
        {page === "food" && <Food />}
        {page === "drinks" && <Drinks />}
        {page === "staff" && <Staff />}
        <Footer/>
    </div>
   
  );
}

export default App;
