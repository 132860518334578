import React from 'react';
import Page from './components/Page'

function App() {
  return (
    <Page />
  );
}

export default App;
