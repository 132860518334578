import React  from 'react';

function App(props) {  
  return (
    <div className="contents-container">
        Staff
    </div>
  );
}

export default App;
