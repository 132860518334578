import React  from 'react';
import Coffee from '../img/Coffee.jpg'
import Tea from '../img/Tea.webp'
import Matcha from '../img/Matcha.webp'
import Others from '../img/Others.jpg'

function App(props) {  
  return (
    <div className="contents-container menu">
      <div className="heading">
        Coffee
      </div>
      <img src={Coffee} />
      <div className="text">
        Latte<br/>
        Mocha<br/>
        Peppermint Mocha<br/>
      </div>
      <div className="divider"></div>
      <div className="heading">
        Matcha
      </div>
      <img src={Matcha} />
      <div className="text">
        Plain<br/>
        Peppermint<br/>
        Jasmine<br/>
        Vanilla<br/>
        <br />
        <i>Available toppings:</i><br />
        Cheese Foam <br />
        Shiratama Dango
      </div>
      <div className="divider"></div>

      <div className="heading">
        Tea
      </div>
      <img src={Tea} />
      <div className="text">
        Jasmine Black Tea<br/>
        Peach Tea<br/>
        Passionfruit Black Tea<br/>
      </div>
      <div className="divider"></div>

      <div className="heading">
        Others
      </div>
      <img src={Others} />
      <div className="text">
        Wang Lao Ji<br/>
        Coke No Sugar<br/>
      </div>
    </div>
  );
}

export default App;
