import React  from 'react';
import Croissant from '../img/Croissant.webp'
import Sushi from '../img/Sushi.jpg'
import Eggplant from '../img/Eggplant.jpg'


function App(props) {  
  return (
    <div className="contents-container menu">
        <div className="heading">
          Sweet
        </div>
        <img src={Croissant} />
        <div className="text">
          Butter Croissant<br/>
          Pain au Chocolat<br/>
          Salted Tahini Cookie<br/>
        </div>
        <div className="divider"></div>
        <div className="heading">
          Savoury
        </div>
        <img src={Eggplant} />
        <div className="text">
          Beef Pie<br/>
          Vege Curry Pie<br/>
          Pork & Fennel Sausage Roll<br/>
          Eggplant & Chickpea Sausage Roll<br/>
          Spinach & Ricotta Bureka<br/>
        </div>
        <div className="divider"></div>
        <div className="heading">
          Sushi
        </div>
        <img src={Sushi} />
        <div className="text">
          Tuna salad cucumber<br/>
          Teriyaki chicken cucumber<br/>
          Lobster salad avocado<br/>
          Prawn avocado<br/>
          Fresh salmon<br/>
        </div>
    </div>
  );
}

export default App;
