import React  from 'react';
import Logo from '../img/LuBros Logo.png'

function App(props) {  
  const setPage = (page) => {
    props.setPage(page)
  }
  
  return (
    <div className="menu-container">
        <img src={Logo} />
        <div className="menu-item-container">
            <div onClick={() => setPage("home")}>Home</div>
            <div onClick={() => setPage("plan")}>Plan </div>
            <div onClick={() => setPage("food")}>Food</div>
            <div onClick={() => setPage("drinks")}>Drinks</div>
        </div>
    </div>
  );
}

export default App;
